import { DragEvent, Fragment, useContext, useState } from 'react';
import styled from 'styled-components';

import { FieldTypeEnum, SectionType } from '@cpm/scanifly-shared-data';
import colors from 'helpers/constants/colors';
import { ChecklistTemplateContext } from 'screens/ChecklistTemplate/Editor/ChecklistTemplateProvider';

import { ReactComponent as GrabIcon } from 'assets/icons/grab-icon.svg';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { useTranslation } from 'react-i18next';
import { FormField } from './FormField';

const SectionHeader = styled.h2`
  text-overflow: ellipsis;
`;

const ComponentsList = styled.ul`
  list-style-type: none;
  padding: 0 0rem;
`;

const ComponentWrapper = styled.li`
  cursor: grab;
  width: 100%;
`;

const StyledGrabIcon = styled(GrabIcon)`
  cursor: grab;
  margin-right: 1rem;
`;

const DraggableWrapper = styled.div`
  cursor: grab;
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;
`;

const Card = styled.div<{ isDragging?: boolean; isEditing?: boolean }>`
  background-color: ${(props) => (props.isEditing ? colors.yellowHighlightOpacity : colors.white)};
  border: 1px solid ${colors.lightGray};
  border-radius: 1.5rem;
  padding: 1.5rem;
  // margin-bottom: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
`;

const Placeholder = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: ${colors.lightGrayOpacity};
  border: 4px dashed ${colors.labelGray};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.labelGray};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
`;

const DropZone = styled.div`
  width: 100%;
  min-height: 1.5rem;
  padding: 0.9rem;
`;

type Props = {
  sectionData: SectionType;
};

function Section({ sectionData: { title, components, id: sectionId } }: Props) {
  const { handleFieldDrop, editing } = useContext(ChecklistTemplateContext);

  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
  const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);
  const [draggedItemHeight, setDraggedItemHeight] = useState<number>(0);
  const { t } = useTranslation();

  const handleDragOver = (index: number) => (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
    if (dragOverIndex !== index) {
      setDragOverIndex(index);
    }
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setDragOverIndex(null);
    }
  };

  const handleDrop = (index: number) => (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (draggedItemIndex !== null && draggedItemIndex !== index) {
      handleFieldDrop(draggedItemIndex, index, sectionId);
    }
    setDraggedItemIndex(null);
    setDragOverIndex(null);
    setDraggedItemHeight(0);
  };

  const handleDragStart =
    (index: number) => (event: DragEvent<HTMLDivElement> | DragEvent<HTMLLIElement>) => {
      setDraggedItemIndex(index);
      const target = event.currentTarget as HTMLDivElement;
      const cardElement = target.parentElement as HTMLElement;
      const height = cardElement.offsetHeight;
      setDraggedItemHeight(height);
      event.dataTransfer.effectAllowed = 'move';

      // Set the drag image to the Card element
      event.dataTransfer.setDragImage(
        cardElement,
        event.clientX - cardElement.getBoundingClientRect().left,
        event.clientY - cardElement.getBoundingClientRect().top
      );
    };

  const handleDragEnd = () => {
    setDraggedItemIndex(null);
    setDragOverIndex(null);
    setDraggedItemHeight(0);
  };

  return (
    <>
      <Card>
        <SectionHeader>{title}</SectionHeader>
      </Card>
      <ComponentsList>
        {components.map((component, index) => {
          const isEditing = editing.includes(component.id);
          const isDragging = draggedItemIndex === index;

          return (
            <Fragment key={component.id}>
              {/* Drop zone before each item */}
              <DropZone
                onDragOver={handleDragOver(index)}
                onDrop={handleDrop(index)}
                onDragLeave={handleDragLeave}
              >
                {dragOverIndex === index && draggedItemHeight > 0 && (
                  <Placeholder height={draggedItemHeight}>
                    {t('ChecklistTemplate.dragAndDropField')}
                  </Placeholder>
                )}
              </DropZone>
              <Card isEditing={isEditing} isDragging={isDragging}>
                <DraggableWrapper
                  draggable={
                    component.componentType !== FieldTypeEnum.notApplicable &&
                    !editing.includes(component.id)
                  }
                  onDragStart={handleDragStart(index)}
                  onDragEnd={handleDragEnd}
                >
                  <StyledGrabIcon />
                </DraggableWrapper>
                <ComponentWrapper
                  draggable={
                    component.componentType !== FieldTypeEnum.notApplicable &&
                    !editing.includes(component.id)
                  }
                  onDragStart={handleDragStart(index)}
                  onDragEnd={handleDragEnd}
                >
                  <FormField item={component} />
                </ComponentWrapper>
              </Card>
            </Fragment>
          );
        })}
        {/* Drop zone at the end of the list */}
        <DropZone
          onDragOver={handleDragOver(components.length)}
          onDrop={handleDrop(components.length)}
          onDragLeave={handleDragLeave}
        >
          {dragOverIndex === components.length && draggedItemHeight > 0 && (
            <Placeholder height={draggedItemHeight}>
              {t('ChecklistTemplate.dragAndDropField')}
            </Placeholder>
          )}
        </DropZone>
      </ComponentsList>
    </>
  );
}

export default Section;
